div.progress-bar-container {
  position: relative;
}

.circle {
  width: 20px;
  height: 20px;
  background-color: #e74c3c; /* Default circle color */
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.circle.completed {
  background-color: #3498db; /* Completed circle color */
}

/* Adjust positioning of circles based on your design */
.circle:nth-child(1) {
  left: 0;
}

.circle:nth-child(2) {
  left: 33%;
}

.circle:nth-child(3) {
  left: 66%;
}

div.background-image-wrapper-kaluza {
  background-image: url("/public/images/kaluza/kaluza-logo.png");
  background-position: center;
  background-repeat: no-repeat;
}

/* div.progress-bar-button-wrapper-class {
  display: none;
} */
