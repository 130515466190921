div.tooltip.show,
div.tool-tip-div,
div.tooltip-inner {
  width: 50vw;
  max-width: 50vw;
  opacity: 100%;
}

div.tooltip-inner {
  padding: 0 0;
}

div.tool-tip-div > p {
  text-align: left;
  background-color: #7500c0;
  height: 100%;
  padding-top: 5px;
  padding-left: 20px;
  padding-bottom: 5px;
}

p > ul > li {
  color: white;
}

p > ul > li:last-child {
  list-style: none;
}

table.table.pin-upload-tooltip-table {
  width: 90%;
}

table.table.pin-upload-tooltip-table > thead {
  font-weight: bold;
}

table.table.pin-upload-tooltip-table > thead > tr:first-child {
  text-align: center;
  background-color: #dcafff;
}

table.table.pin-upload-tooltip-table > thead > tr:last-child {
  background-color: #eacfff;
}

table.table.pin-upload-tooltip-table > thead > tr > td,
table.table.pin-upload-tooltip-table > tbody > tr > td {
  padding: 0 0;
}
