.unauthorised-container {
  background-color: black;
  height: 70%;
  color: white;
  border-radius: 20px;
}

.unauthorised-first-row {
  /* background-color: red; */
  height: 30%;
  align-items: center;
  text-align: center;
}

.unauthorised-first-row > .col > img.palm-picture {
  width: 7%;
}

.unauthorised-first-row > .col {
  font-weight: bolder;
  font-size: 3.5em;
}

.unauthorised-second-row {
  /* background-color: blue; */
  height: 50%;
  align-items: center;
  text-align: center;
  font-size: 2.25em;
}

.unauthorised-third-row {
  height: 20%;
  text-align: center;
}

button.unauthorised-go-back-button,
button.unauthorised-go-back-button:hover {
  cursor: pointer;
  background-color: var(--common-gray-3);
  border-color: var(--common-gray-3);
}
