div.react-datepicker-wrapper,
input.date-pick {
  width: 100%;
}

input.date-pick {
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  padding: 0.375rem 0.75rem;
}
