div.kaluza-nmi-discovery-type-3-search-title {
  background: linear-gradient(
    90deg,
    rgba(87, 212, 247, 1) 0%,
    rgba(6, 27, 50, 1) 100%
  );
  color: white;
  padding: 5px 5px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  font-weight: bold;
}
