:root {
  --acn-purple: #a100ff;

  --common-gray-1: #837f89;
  --common-gray-2: #d3d3d3;
  --common-gray-3: #bfbfbf;
  --common-gray-4: #e7e7e7;

  --common-blue-3: #58d5f8;
  --common-blue-2: #0530fa;

  --common-black-1: #212528;

  --common-pink-1: #ff48d8;

  --common-green-1: #85cf9c;

  --common-orange-1: #ff8134;

  --common-yellow: #ffcc06;

  /* essential energy */
  --common-blue-4: #002263;
  --common-orange-2: #ff5300;
  --common-gray-5: #f2f4f7;
}
