div.master-txn-container-wrapper {
  width: 90%;
  background-color: RGBA(255, 255, 255, 0.8);
  margin-top: 10px;
  margin-bottom: 60px;
  box-shadow: 1px 0px 10px 5px rgba(0, 0, 0, 0.19);
  -webkit-box-shadow: 1px 0px 10px 5px rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 1px 0px 10px 5px rgba(0, 0, 0, 0.19);
}

div.background-image-wrapper {
  background-position: center;
  background-repeat: no-repeat;
}

div.spinner-div.master-txn-view-spinner-wrapper {
  min-height: 650px;
}

div.col.spinner-col {
  margin-top: auto;
  margin-bottom: auto;
}

div.spinner-master-txn-viewer {
  scale: 3.5;
}

div.background-image-wrapper#octo {
  background-image: url("/public/images/octo-logo.png");
}

div.background-image-wrapper#eql {
  background-image: url("/public/images/eql-logo.png");
}

div.background-image-wrapper#origin {
  background-image: url("/public/images/origin-logo.png");
  background-size: 35% auto;
}

div.background-image-wrapper#nectr {
  background-image: url("/public/images/nectr/nectr-logo.png");
}

div.background-image-wrapper#kaluza {
  background-image: url("/public/images/kaluza/kaluza-logo.png");
}

div.background-image-wrapper#intellihub {
  background-image: url("/public/images/intellihub/intellihub.png");
}

div.background-image-wrapper#essential {
  background-image: url("/public/images/essential-energy/essential-energy-logo.png");
  /* width: 50px;
  height: auto; */
  background-size: 25% auto;
}

div.master-transaction-row,
div.objections-details-row,
div.initiating-transaction-row,
div.associated-txn-row {
  margin-top: 10px;
}

div.master-txn-container-wrapper.container-fluid
  > div.master-txn-wrapper-child {
  padding-right: 10px;
  padding-left: 10px;
}

div.master-txn-container-wrapper.container-fluid
  > div.master-txn-wrapper-child:last-child {
  padding-bottom: 10px;
}

div.master-txn-wrapper-child > div.col {
  border: 1px solid rgba(0, 0, 0, 0.23);
  padding-top: 10px;
  padding-bottom: 10px;
}
