a.view-data-link {
  cursor: pointer;
}

table.associated-transactions-table > thead {
  color: var(--common-orange-2);
}
table.associated-transactions-table > thead > tr > th {
  font-weight: bold !important;
}
/* TODO: bg color for eql, origin and default tenant */
table.associated-transactions-table > thead.octo {
  background-color: var(--common-pink-1);
}

table.associated-transactions-table > thead.eql {
  background-color: var(--common-green-1);
}

table.associated-transactions-table > thead.origin {
  background-color: var(--common-orange-1);
}

table.associated-transactions-table > thead.nectr {
  background-color: var(--common-yellow);
}

table.associated-transactions-table > thead.kaluza {
  background-color: var(--common-blue-3);
}

table.associated-transactions-table > thead.essential {
  background-color: white;
}

table.associated-transactions-table > thead.kaluza {
  background-color: var(--common-blue-3);
}

table.associated-transactions-table > thead.intellihub {
  background-color: var(--common-blue-2);
}

table.associated-transactions-table > thead.default-tenant {
  background-color: var(--acn-purple);
}

table.associated-transactions-table > thead > tr > th {
  font-weight: lighter;
}

div.file-viewer-modal {
  width: 90vw;
  max-width: 90vw;
}

div.modal-body {
  height: 75vh;
  max-height: 75vh;
}

div.data-viewer-modal-content {
  border-radius: 0;
}

div.data-viewer-modal-content > div > div.modal-title {
  font-weight: bold;
}

pre.json-contents,
pre.csv-contents,
pre.xml-contents {
  height: 65vh;
  max-height: 65vh;
}

div.modal-header {
  border-bottom: 0;
}

button.btn-close {
  background-image: url("/public/icons/close-btn-icon.svg");
}
