div.standing-data-tab > a.nav-link {
  font-weight: bold;
  color: black;
}

div.active-standing-data-tab > a {
  /* background: linear-gradient(
    90deg,
    rgba(87, 212, 247, 1) 0%,
    rgba(6, 27, 50, 1) 100%
  ) !important; */
  background: linear-gradient(
    90deg,
    rgba(0, 176, 240, 1) 0%,
    rgba(5, 60, 249, 1) 100%
  ) !important;
  color: white !important;
}

div.inactive-standing-data-tab > a {
  background-color: var(--common-gray-4) !important;
}

div.standing-data-result-wrapper {
  border-right: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
  border-left: 1px solid #e7e7e7;

  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

div.standing-data-meter-result-wrapper {
  border-right: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
  border-left: 1px solid #e7e7e7;
}

div.standing-data-meter-result-wrapper:nth-child(odd) {
  background-color: var(--common-gray-4) !important;
}

div.standing-data-meter-result-wrapper:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  margin-bottom: 20px;
}

h2.standing-data-accordion-header > button {
  color: black !important;

  border-right: 1px solid var(--common-gray-3) !important;
  border-bottom: 1px solid var(--common-gray-3) !important;
  border-left: 1px solid var(--common-gray-3) !important;

  text-align: center !important;
}

h2.standing-data-accordion-header > button.accordion-button:not(.collapsed) {
  color: black !important;
}

h2.standing-data-accordion-header.even > button {
  background-color: white !important;
}

h2.standing-data-accordion-header.odd > button {
  background-color: var(--common-gray-4) !important;
}

div.standing-data-accordion-body {
  border-right: 1px solid var(--common-gray-3) !important;
  border-bottom: 1px solid var(--common-gray-3) !important;
  border-left: 1px solid var(--common-gray-3) !important;
}

h2.standing-data-accordion-header
  > button.accordion-button:not(.collapsed)::after {
  background-image: url("../../../../public/icons/caret-down-fill-2.svg") !important;
}

h2.standing-data-accordion-header > button.accordion-button::after {
  background-image: url("../../../../public/icons/caret-down-fill-2.svg") !important;
}
