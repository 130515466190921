div#root-2 {
  height: 100vh;
  background-color: white;
}

.header-logo-right {
  padding-right: 0.5rem !important;
}

.vertical-line-navbar {
  border-left: 1px solid white;
  height: 50px;
}

.nav-acn-logo {
  margin-left: 0.25rem !important;
}

.header-active {
  color: rgba(255, 255, 255, 0.55);
  text-decoration: none !important;
  padding: 0.2rem 1rem 0.2rem 1rem !important;
}

a.header-active:hover {
  color: white !important;
}

a.header-active:visited {
  color: white !important;
}

.header-active.active {
  color: white;
  border-bottom: 4px solid var(--acn-purple);
}

div.dropdown-menu.show {
  border-radius: 0;
  padding-top: 0;
  padding-bottom: 0;
}

span.env-badge.badge {
  background-color: transparent !important;
  border: solid white 1px;
  font-size: x-small;
}

.header-disable {
  color: rgba(255, 255, 255, 0.55);
  text-decoration: none !important;
  padding: 0.2rem 1rem 0.2rem 1rem !important;
}

.navbar-secondary {
  height: 40px;
  background-color: white;
}

#header-nav-dropdown-admin {
  color: black;
  text-decoration: none !important;
  padding: 0.2rem 1rem 0.2rem 1rem !important;
}

#header-nav-dropdown-admin:hover {
  color: black;
}

div.dropdown-menu.show > a:hover {
  background-color: var(--common-blue-4);
  color: white;
  font-weight: bold !important;
}

#header-nav-dropdown-admin.dropdown-toggle.nav-link.active {
  border-bottom: 4px solid var(--acn-purple);
}

.nav-logout {
  padding-right: 0.5rem !important;
  cursor: pointer;
}

.logout-btn {
  border-bottom: 4px solid transparent;
  color: black;
}

.active-drop-down-nav {
  /* color: white; */
  border-bottom: 4px solid var(--common-orange-2);
}
