div.forgot-password-line {
  text-align: right;
}

span.span-forgot-pw {
  cursor: pointer;
  color: var(--acn-purple);
}

span.span-forgot-pw:hover {
  cursor: pointer;
  color: var(--common-gray-1);
}
