div.master-transaction-type-drop-down-menu {
  min-height: 100px;
  max-height: 300px;
  overflow-y: scroll;
  width: 100%;
}

button.master-transaction-type-drop-down-btn,
button.master-transaction-type-drop-down-btn.show {
  width: 100%;
  color: var(--common-black-1);
  background-color: #fff;
  border: 1px solid #ced4da;
}
button.master-transaction-type-drop-down-btn:hover,
button.master-transaction-type-drop-down-btn:active {
  background-color: #fff;
  border: 1px solid #ced4da;
  color: var(--common-black-1);
}
div.master-transaction-type-drop-down-menu > a.dropdown-item:hover {
  background-color: var(--common-black-1);
  color: white;
}

div.month-year-container {
  display: flex;
}

div.month-year-container > div#dropdown-date {
  flex: 1;
}

div#dropdown-year > select,
div#dropdown-month > select {
  width: 100% !important;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  padding: 5px 5px;
}

button.date-month-selection-btn {
  margin-top: 10px;
  width: 75px !important;
  padding: 2px 2px !important;
  background-color: var(--common-black-1);
  border-color: var(--common-black-1);
}

button.date-month-selection-btn:hover {
  background-color: var(--common-black-1) !important;
  border-color: var(--common-black-1);
}
