div.div-sign-in-wrapper {
  height: 100vh;

  background: linear-gradient(180deg, rgba(117, 0, 192, 1) 0%, rgba(190, 130, 255, 1) 50%);
}

div.sign-in-div {
  height: 100%;
}

form.regular-sign-in-form {
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  min-width: 320px;
  width: 20%;
  /* min-height: 570px; */
  /* height: 60vh; */
  filter: drop-shadow(0 0 0.75rem black);
}

div.child-form {
  padding-left: 20px;
  padding-right: 20px;
}

div.table-banner-form-group {
  margin-bottom: 20px;
  min-height: 60px;
}

table.table-banner {
  text-align: center;
}

button.sign-in-button,
button.send-mfa-code-button,
button.submit-new-password-btn,
button.show-qr-code-btn,
button.submit-mfa-button {
  width: 100%;
  color: white;
  background-color: #a100ff;
  border-color: #a100ff;
}

button.sign-in-button:disabled {
  width: 100%;
  color: white;
  background-color: #a100ff;
  border-color: #a100ff;
  opacity: 30%;
}

input.form-check-input[type="checkbox"] {
  border-radius: 0;
}

div.qr-code-holder {
  text-align: center;
}
button.send-mfa-code-button {
  margin-top: 10px;
}

button.sign-in-button:hover,
button.forgot-password-btn:hover,
button.send-mfa-code-button:hover,
button.submit-new-password-btn:hover,
button.show-qr-code-btn:hover,
button.submit-mfa-button:hover {
  background-color: #837f89;
  border-color: #837f89;
  color: white;
}

.spinner-div {
  margin-top: 10px;
  text-align: center;
}

.create-new-pw-div > form {
  margin-top: 10px;
  width: 20%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}

button.create-new-pw-button {
  margin-top: 5px;
  background-color: var(--acn-purple);
  border-color: var(--acn-purple);
}

div.alternative-sign-in-button-wrapper {
  /* align-items: end; */
  text-align: right;
  padding-right: 20px;
  padding-bottom: 20px;
}

span.alternative-sign-in-text {
  font-size: x-small;
}

button.alternative-sign-in-btn {
  padding: 2px 2px;
  background-color: var(--common-gray-3);
  border-style: none;
}

button.alternative-sign-in-btn:hover {
  padding: 2px 2px;
  background-color: var(--common-gray-2);
  border-style: none;
}

img.alternative-sign-in-acn {
  background-color: var(--common-gray-3);
  border-radius: 3px;
  padding: 2px 2px;
  cursor: pointer;
}
