div.realms-page-container {
  margin-left: 0;
  margin-right: 0;
  /* height: 100vh; */
  /* align-items: center; */
}

div.realms-page-row {
  height: 100vh;
  width: 100vw;
}
div.realms-page-vertical-line {
  border-left: 2px solid black;
  border-right: 2px solid black;
  height: 80px;

  /* padding-left: 10px; */
  margin-left: 10px;
  margin-right: 10px;
}

td.reals-page-mdh-text {
  font-size: xx-large;
  font-weight: light;
}

img.realms-page-acn-logo {
  width: 300px !important;
  height: auto;
}

table.reals-page-table-banner {
  /* align-items: center; */
  text-align: center;
  width: fit-content;
}

div.realms-page-right-column-row1,
div.realms-page-right-column-row2 {
  height: 50vh;
}

div.realms-page-right-column-row2 {
  align-items: end;

  text-align: left;
}

div.realms-page-right-column {
  align-items: center;
}

img.realms-page-cover-image {
  height: 100vh;
}

div.realms-page-list-item {
  cursor: pointer;
  width: 70%;
}

div.realms-page-list-item2 {
  cursor: pointer;
}

div.realms-page-list-item:hover,
div.realms-page-list-item2:hover {
  background-color: var(--common-gray-3);
}

div.alternative-sign-in-div {
  padding-right: 20px;
  padding-bottom: 20px;
}
