.list-group-item.active {
  color: black !important;
  background-color: var(--common-gray-2) !important;
  border-color: var(--common-gray-2) !important;
}

.accordion-body.gateway-throughput-accordion {
  padding: 0 0 !important;
}

.list-group {
  border-radius: 0 !important;
}

.list-group-item {
  border-width: 0 0 0 0 !important;
}
