div.so-report-gradient {
  background: linear-gradient(
    90deg,
    rgba(0, 176, 240, 1) 0%,
    rgba(4, 68, 248, 1) 100%
  );
}

/* div.sord-request-row {
  margin-top: 10px;
  align-items: center;
} */
/* 
div.inner {
  padding: 10px;
} */

div.result-number {
  font-weight: bolder;
  text-align: center;
  font-size: 5rem;
}
