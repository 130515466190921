div.origin-nmi-search-data-type-header,
div.origin-gradient {
  background: linear-gradient(90deg, rgba(247, 7, 10, 1) 0%, rgba(255, 182, 46, 1) 100%) !important;
  color: white;
  padding: 5px 5px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  font-weight: bold;
}

div.nectr-nmi-search-data-type-header,
div.nectr-gradient {
  background: linear-gradient(90deg, rgba(255, 146, 39, 1) 0%, rgba(255, 241, 63, 1) 100%) !important;
  color: white;
  padding: 5px 5px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  font-weight: bold;
}

div.search-result-div,
div.bottom-div {
  padding: 10px 10px;
  border: solid 1px #e7e7e7;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

div.export-csv-button-div-wrapper {
  text-align: right;
}

button.csv-export-button {
  background-color: black;
  color: white;
}

button.csv-export-button:hover {
  background-color: rgb(41, 33, 33);
  color: white;
}
