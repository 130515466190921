.accordion-body.gateway-throughput-accordion {
  padding: 0 0 !important;
}

.list-group {
  border-radius: 0 !important;
}

.list-group-item {
  border-width: 0 0 0 0 !important;
}
