div.search-form-wrapper {
  padding: 2rem 3rem 1rem 3rem;
  height: 80vh;
}

div.search-input-column {
  height: 75vh;
  padding-right: 0;
  padding-left: 0;
}

div.search-result-column {
  /* height: 75vh; */
  padding-left: 50px;
}

.search-input-title,
.search-results-title {
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  color: white;
}

.search-input-title#octo,
.search-results-title#octo {
  background-color: var(--common-pink-1);
}

.search-input-title#eql,
.search-results-title#eql {
  background-color: var(--common-green-1);
}

.search-input-title#origin,
.search-results-title#origin {
  background-color: var(--common-orange-1);
}

.search-input-title#nectr,
.search-results-title#nectr {
  background-color: var(--common-yellow);
}

.search-input-title#kaluza,
.search-results-title#kaluza {
  background-color: var(--common-blue-3);
}

.search-input-title#intellihub,
.search-results-title#intellihub {
  background-color: var(--common-blue-2);
}

.search-input-title#essential,
.search-results-title#essential {
  background-color: var(--common-blue-4);
}

.search-input-title#default-tenant,
.search-results-title#default-tenant {
  background-color: var(--acn-purple);
}

div.accordion-item > h2 > button {
  height: 40px;
  padding-left: 8px;
}

.accordion-button {
  background-color: white !important;
  border-radius: 0 0 0 0 !important;
  font-weight: bold;
  border-bottom: 1px solid var(--common-gray-4) !important;
}

.accordion-button:not(.collapsed) {
  color: black !important;
  font-weight: bold;
}

button.accordion-button:not(.collapsed)::after {
  /* background-image: url("../../../../public/icons/caret-down-fill.svg"); */
  background-image: url("../../../public/icons/caret-down-fill.svg");
}

button.accordion-button.collapsed::after {
  /* background-image: url("../../../../public/icons/caret-down-fill.svg"); */
  background-image: url("../../../public/icons/caret-down-fill.svg");
}

.accordion-button.collapsed {
  color: black;
}
div.accordion-item {
  border-style: none;
}

button.accordion-button:focus {
  box-shadow: none;
}

div.search-button-div {
  text-align: right;
}

button.search-btn {
  color: white;
  background-color: var(--common-orange-2);
  border-color: var(--common-orange-2);
}

button.search-btn:hover {
  background-color: var(--common-gray-1);
  border-color: var(--common-gray-1);
  color: white;
}

div.accordion-item:last-of-type {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

div.accordion-item:first-of-type {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

div.search-result-tab-content,
div.search-input-column {
  box-shadow: 1px 0px 10px 5px rgba(0, 0, 0, 0.19);
  -webkit-box-shadow: 1px 0px 10px 5px rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 1px 0px 10px 5px rgba(0, 0, 0, 0.19);
}

div.search-result-tab-content {
  height: 100%;
}

div.empty-div {
  /* height: 55vh; */
  height: 100%;
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

div.spinner-div-searching {
  height: 100%;
  text-align: center;
}
