
div.fuel-type-selection-btn-groups {
  width: 100%;
}

label.btn-btn-group-num-0,
label.btn-btn-group-num-1 {
  width: 50%;
  border: 1px solid #000000 !important;
  background-color: white !important;
  color: black;
}

label.btn-btn-group-num-0:hover,
label.btn-btn-group-num-1:hover {
  background-color: var(--common-gray-2) !important;
  color: black !important;
}

div.fuel-type-selection-btn-groups > input.btn-check:checked + label.btn-btn-group-num-0:hover,
div.fuel-type-selection-btn-groups > input.btn-check:checked + label.btn-btn-group-num-1:hover {
  color: white !important;
}

div.fuel-type-selection-btn-groups > input.btn-check:checked + .btn {
  background-color: black !important;
}

/* div.fuel-type-selection-btn-groups > input.btn-check {
  background-color: red !important;
} */
