nav.header-sign-in {
  height: 75px;
}

table.sign-in-banner {
  background-color: black;

  vertical-align: middle;
  height: 10vh;
}

table.sign-in-banner > tbody > tr > td:first-child {
  width: 50%;
}

img.sign-banner-acn-brand {
  width: 80%;
  height: auto;
}

div.vertical-line {
  border-left: 1px solid white;
  height: 80%;
}

td.td-mdh-text {
  color: white;
  font-size: larger;
}
