table.reports-queues-table {
  text-align: center;
}

thead > tr > td.color-deco,
tbody > tr > td.color-deco {
  background-color: #c6da9a;
  color: white;
}

thead > tr > td.color-puerto-rico,
tbody > tr > td.color-puerto-rico {
  background-color: #4dc2a8;
  color: white;
}
