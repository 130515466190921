td.master-txn-id-td {
  cursor: pointer;
  text-decoration: underline;
  color: blue;
}

td.master-txn-id-td:hover {
  color: #837f89;
}

div.search-result-wrapper {
  /* height: 50vh; */
  padding-left: 5px;
  padding-right: 5px;
}

/* TODO:put this on another file */
table.search-result-table > thead {
  color: var(--common-orange-2);
}

table.search-result-table > thead > tr > th {
  font-weight: bold !important;
}

/* TODO: bg color for eql, origin and default tenant */
table.search-result-table > thead.octo {
  background-color: var(--common-pink-1);
}

table.search-result-table > thead.eql {
  background-color: var(--common-green-1);
}

table.search-result-table > thead.origin {
  background-color: var(--common-orange-1);
}

table.search-result-table > thead.nectr {
  background-color: var(--common-yellow);
}

table.search-result-table > thead.kaluza {
  background-color: var(--common-blue-3);
}

table.search-result-table > thead.intellihub {
  background-color: var(--common-blue-2);
}

table.search-result-table > thead.essential {
  background-color: white;
}

table.search-result-table > thead.default-tenant {
  background-color: var(--acn-purple);
}
table.search-result-table > thead > tr > th {
  font-weight: lighter;
}

div.react-bootstrap-table-pagination-list > ul {
  float: right;
}

div.react-bootstrap-table-pagination-list > ul > li > a {
  border: 1px solid #ddd;
  padding: 6px 12px;
}

div.react-bootstrap-table-pagination-list > ul > li > a:hover {
  cursor: pointer;
}
