div.error-page-container {
  padding: 2rem 3rem 1rem 3rem;
  height: 80vh;
}

div.error-page-column {
  text-align: center;
  /* margin-right: auto;
  margin-left: auto; */
  color: white;
  width: 100%;
  height: 100%;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

div.error-page-text {
  font-weight: bolder;
  font-size: xx-large;
}

button.go-back-btn {
  color: white;
  background-color: var(--acn-purple);
  border-color: var(--acn-purple);
}

button.go-back-btn:hover {
  background-color: var(--common-gray-1);
  border-color: var(--common-gray-1);
  color: white;
}
