div.pin-upload-container {
  color: #222222;
  margin-top: 3%;
  width: 80%;
  min-width: 1000px;
  /* height: 70vh; */
  /* height: auto; */
  box-shadow: 1px 0px 10px 5px rgba(0, 0, 0, 0.19);
  -webkit-box-shadow: 1px 0px 10px 5px rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 1px 0px 10px 5px rgba(0, 0, 0, 0.19);
  margin-bottom: 60px;
}

div.pin-upload-menu-contents-wrapper {
  padding-left: 100px;
  padding-right: 100px;
  /* height: 55vh; */
}

div.pin-upload-menu-contents {
  text-align: center;
  height: 100%;
  border-radius: 50px;
  border: 1px dashed rgba(0, 0, 0, 0.5);
  padding-top: 20px;
  padding-bottom: 20px;
}

div.pin-upload-menu-contents:hover {
  background-color: var(--common-gray-2);
  cursor: pointer;
}

div.pin-upload-menu-contents.with-pin-files {
  background-color: var(--common-gray-2);
  height: 500px;
  margin-bottom: 60px;
}

div.pin-files-list {
  display: grid;
  align-content: space-evenly;
  max-height: 80%;
  height: 80%;
}
div.pin-files {
  width: 75%;
  margin: auto;
  border-radius: 10px;
  background-color: white;
  height: 50px;
}

div.pin-upload-file-name {
  text-align: left;
  display: flex;
  align-items: center;
}

button.remove-files-btn {
  color: black;
  background-color: transparent;
  border-style: none;
}

button.remove-files-btn:hover {
  color: white;
  background-color: red;
  border-style: none;
}

button.remove-files-btn:disabled {
  display: none;
}

button.select-file-btn,
button.select-additional-files-btn,
button.upload-file-btn {
  color: white;
  background-color: var(--common-blue-4);
  border-color: var(--common-blue-4);
}

button.select-file-btn:hover,
button.select-additional-files-btn:hover,
button.upload-file-btn:hover {
  background-color: var(--common-gray-1);
  border-color: var(--common-gray-1);
  color: white;
}

div.pin-upload-title {
  background-color: var(--common-blue-4);
  color: white;
  font-weight: lighter;
  padding-top: 5px;
  padding-bottom: 5px;
}

svg.pin-upload-cloud {
  fill: gray;
}

button.pin-upload-tool-tip {
  padding: 0 0 0 0;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  font-style: italic;
  font-size: large;
  font-weight: bolder;
  background-color: var(--common-blue-4);
}

button.pin-upload-tool-tip:hover {
  background-color: var(--common-blue-4);
}

div.i-icon-file-upload {
  text-align: right;
}

/* TODO: move to other file */

div.alert-pin-upload-success {
  position: fixed;
  top: 30%;
  left: 80%;
  width: 20%;
  z-index: 9999;
  border-radius: 10px;
  background-color: var(--common-blue-4);
  border-style: none;
  color: white;
}
