div.move-in-alert {
  position: fixed;
  top: 30%;
  /* left: 40%; */
  /* width: 30%; */
  z-index: 9999;
  font-size: small;
  border-radius: 10px;
  background-color: var(--common-blue-3);
  border-style: none;
  color: white;
}
